export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const API_LOADING = "API_LOADING";
export const INFO_MSG = "INFO_MSG";
export const ERROR_MSG = "ERROR_MSG";
export const SUCCESS_MSG = "SUCCESS_MSG";
export const PAGE_LOADING = "PAGE_LOADING";
export const UPDATE_SIGNUP_STATE = "UPDATE_SIGNUP_STATE";
export const UPDATE_ONBOARD_STATUS = "UPDATE_ONBOARD_STATUS";
export const USERID_FETCH_SUCCESS = "USERID_FETCH_SUCCESS";
export const SET_SUBSCRIPTION_PLAN = "SET_SUBSCRIPTION_PLAN";
export const CLEAR_SUBSCRIPTION_PLAN = "CLEAR_SUBSCRIPTION_PLAN";
