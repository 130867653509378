import axios from "axios";

export const getAllItems = () => {
	return axios.get(
		`/api/resource/Item?fields=["*"]&limit=0&order_by=modified desc`
	);
};

export const getMultipleItems = (itemArray: string) => {
	return axios.get(
		`/api/resource/Item?filters=[["item_code","in",[${itemArray}]]]&fields=["*"]&limit=0&order_by=modified desc`
	);
};

export const getAnItem = (itemId: string) => {
	return axios.get(
		`/api/method/ace_be_app.api.item.get_item?item_code=${itemId}`
	);
};

export const deleteItem = (itemId: string) => {
	return axios.get(
		`/api/method/ace_be_app.api.item.delete?item_code=${itemId}`
	);
};

export const updateItem = (data: any) => {
	return axios.post(`/api/method/ace_be_app.api.item.update`, data);
};

export const downloadItemCostingReport = (itemId: string) => {
	return axios.get(
		`/api/method/ace_be_app.printformat.item.get_format?code=${itemId}`
	);
};
