import axios from 'axios';

export const fetchUserDetails = () => {
    return axios.get(`/api/method/ace_be_app.api.customer.get_customer`);
}

export const updateUserDetails = (data : any) => {
    return axios.post(`/api/method/ace_be_app.api.customer.update`, data);
}

export const updatePassword = (data : any) => {
    return axios.get(`/api/method/ace_be_app.api.auth.change_password?current_password=${data.current_password}&new_password=${data.new_password}`);
}
