import mixpanel from "mixpanel-browser";

// check environment
let env_check = process.env.REACT_APP_NODE_ENV;
if (env_check === "development") {
	mixpanel.init("dea622f00932ab15d299c4335fc9e0e6", { track_pageview: true });
} else if (env_check === "production") {
	mixpanel.init("3534ed81cc7723d71c118615c39e93d9", { track_pageview: true });
}
let actions = {
	identify: (id) => {
		if (env_check) mixpanel.identify(id);
	},
	alias: (id) => {
		if (env_check) mixpanel.alias(id);
	},
	track: (name, props) => {
		if (env_check) mixpanel.track(name, props);
	},
	track_links: (id, name, props) => {
		if (env_check) mixpanel.track_links(id, name, props);
	},
	people: {
		set: (props) => {
			if (env_check) mixpanel.people.set(props);
		},
	},
	track_pageview: () => {
		if (env_check) mixpanel.track_pageview();
	},
};

export let Mixpanel = actions;
