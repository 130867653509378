import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Box } from '@chakra-ui/react';

interface ScrollableImageVideoProps {
  imagePaths: string[];
}

const ScrollableImageVideo: React.FC<ScrollableImageVideoProps> = ({ imagePaths }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"]
  })
  
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  return (
    <Box w="100%" h={{base: "45vh",sm: "60vh",md: "70vh"}} position="relative" ref={ref} overflowX="hidden">
      <Box as={motion.div} aspectRatio={1.5/1.4} w="auto" h={{base: "45vh",sm: "60vh",md: "70vh"}} bgImage={imagePaths[0]} mx="auto" position="absolute" inset="0" zIndex={20} backgroundSize="cover" style={{opacity}}></Box>
      <Box as={motion.div} aspectRatio={1.5/1.4} w="auto" h={{base: "45vh",sm: "60vh",md: "70vh"}} bgImage={imagePaths[1]} mx="auto" position="absolute" inset="0" zIndex={10} backgroundSize="cover"></Box>
    </Box>
  );
};

export default ScrollableImageVideo;