import React, { Suspense, lazy, useEffect } from "react";
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PublicLayout } from "./layouts/publicLayout";
import RoutesList from "./routes/Routes";
import PrivateLayout from "./layouts/privateLayout";
import { useSelector, useDispatch } from "react-redux";
import {
	ThemeProvider,
	theme,
	ColorModeProvider,
	CSSReset,
	useMediaQuery,
} from "@chakra-ui/react";
import "./App.css";
import LandingPage from "./pages/LandingPage/LandingPage";
import {
	AppDispatch,
	logoutAce,
	setMessage,
	ToastNotification,
} from "./packages";
import { Mixpanel } from "./Mixpanel.js";
import TagManager from "react-gtm-module";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import Cookies from "js-cookie";
const Login = lazy(() => import("./pages/Auth/Login"));
const Home = lazy(() => import("../src/pages/Dashboard/Dashboard"));
const NotFound = lazy(() => import("./pages/Error/NotFound"));
const MobileRestriction = lazy(() => import("./pages/MobileRestriction"));

type Environment = keyof typeof dataDodArgs;

// --------------------DataDog implementation--------------------
const dataDodArgs = {
	production: {
		applicationId: "af51b725-bb4f-40bb-8234-b6c860e195de",
		clientToken: "pubea433cdbc25f7b47620e15e40910f3bd",
		service: "ace_frontend_prod",
		env: "production",
	},
	development: {
		applicationId: "ad7ce774-bee4-4ee0-a14b-02d028222eb3",
		clientToken: "pub85a14d10261dd9221fc35a736c3800bc",
		service: "ace_frontend_dev",
		env: "development",
	},
};

const environment: Environment =
	(process.env.REACT_APP_NODE_ENV as Environment) || "development";

if (environment !== "development") {
	datadogRum.setUser({
		name: Cookies.get("full_name") || "Guest User",
		email: Cookies.get("user_id") || "Guest Email",
	});
	datadogRum.init({
		applicationId: dataDodArgs[environment].applicationId,
		clientToken: dataDodArgs[environment].clientToken,
		site: "datadoghq.com",
		service: dataDodArgs[environment].service,
		env: window.location.hostname.split(".")[0],
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});

	datadogLogs.init({
		clientToken: dataDodArgs[environment].clientToken,
		site: "datadoghq.com",
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
		env: environment,
		service: "SUPPLIER",
	});

	datadogRum.startSessionReplayRecording();
}
// --------------------DataDog implementation--------------------

// --------------------------GTM--------------------------
const tagManagerArgs = {
	gtmId: "GTM-MH7QVBV",
	gtmAuth: "XjDXBMxpQaspJ-JchCcHjg",
};

if (environment !== "development") {
	TagManager.initialize({
		gtmId: tagManagerArgs.gtmId,
		dataLayer: { gtmAuth: tagManagerArgs.gtmAuth },
	});
}
// --------------------------GTM--------------------------

const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const location = useLocation();
	const [authState] = useSelector((state: any) => {
		return [state.AceAuth];
	});
	let isLoggedIn = authState.isLoggedIn;

	if (!isLoggedIn) {
		return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
	}

	return children;
};

const App: React.FC = () => {
	const [isMobile] = useMediaQuery("(max-width: 768px)");
	const dispatch = useDispatch<AppDispatch>();
	const [authState] = useSelector((state: any) => {
		return [state.AceAuth];
	});
	let isLoggedIn = authState.isLoggedIn;
	const toastMsg = useSelector((state: any) => state.message);
	Mixpanel.track_pageview();

	useEffect(() => {
		if (toastMsg?.message?.data) {
			setTimeout(() => {
				dispatch(setMessage(""));
			}, 3000);
		}
	}, [toastMsg]);

	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			return response;
		},
		function (error: AxiosError) {
			if (error.response?.status === 403) {
				if (isLoggedIn) {
					dispatch(logoutAce());
				}
			}
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (isLoggedIn && environment !== "development") {
			// setting  the logged in user session
			datadogRum.setUser({
				name: Cookies.get("full_name"),
				email: Cookies.get("user_id"),
			});
		}
	}, [isLoggedIn]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<ColorModeProvider>
					<CSSReset />
					<BrowserRouter>
						<Routes>
							{RoutesList.filter((route) => route.type === "landing").map(
								(route, index) =>
									isLoggedIn ? (
										<Route
											key={index}
											path="/fileupload"
											element={
												<Suspense>
													<PrivateLayout Component={Home} />
												</Suspense>
											}
										/>
									) : (
										<Route
											key={index}
											path={route.link}
											element={<LandingPage />}
										/>
									)
							)}
							{RoutesList.filter((route) => route.type === "public").map(
								(route, index) =>
									isMobile && environment !== "development" ? (
										<Route path="/*" element={<MobileRestriction />} />
									) : isLoggedIn ? (
										<Route
											key={index}
											path="/fileupload"
											element={
												<Suspense>
													<PrivateLayout Component={Home} />
												</Suspense>
											}
										/>
									) : (
										<Route
											key={index}
											path={route.link}
											element={
												<Suspense>
													<PublicLayout Component={route.component} />
												</Suspense>
											}
										/>
									)
							)}
							{RoutesList.filter((route) => route.type === "private").map(
								(route, index) =>
									isMobile && environment !== "development" ? (
										<Route path="/*" element={<MobileRestriction />} />
									) : isLoggedIn ? (
										<Route
											key={index}
											path={route.link}
											element={
												<Suspense>
													<PrivateLayout Component={route.component} />
												</Suspense>
											}
										/>
									) : (
										<Route
											key={index}
											path="/login"
											element={
												<RequireAuth>
													<Suspense>
														<PublicLayout component={Login} />
													</Suspense>
												</RequireAuth>
											}
										/>
									)
							)}
							{isMobile && environment !== "development" ? (
								<Route path="/*" element={<MobileRestriction />} />
							) : isLoggedIn ? (
								<>
									{RoutesList.filter(
										(route: any) => route.type === "public"
									).map((route: any, index: number) => (
										<Route
											key={index}
											// exact
											path={route.link}
											element={
												<Suspense>
													<Navigate to="/fileupload" replace={false} />
												</Suspense>
											}
										/>
									))}
									<Route
										path="*"
										element={
											<Suspense>
												<PrivateLayout Component={NotFound} />
											</Suspense>
										}
									/>
									<Route
										path="/"
										element={
											<Suspense>
												<Navigate to="/fileupload" replace={false} />
											</Suspense>
										}
									/>
								</>
							) : (
								<>
									<Route
										path="/"
										element={
											<Suspense>
												<Navigate to="/" replace={false} />
											</Suspense>
										}
									/>
									<Route
										path="*"
										element={
											<Suspense>
												<RequireAuth>
													<Navigate to="/login" replace={false} />
												</RequireAuth>
											</Suspense>
										}
									/>
								</>
							)}
						</Routes>
					</BrowserRouter>
					{toastMsg?.message?.data && <ToastNotification toastMsg={toastMsg} />}
				</ColorModeProvider>
			</ThemeProvider>
		</>
	);
};
export default App;
