import React from "react";
import { Box, Image, Text, Flex, Stack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import "./swiper-styles.css";
import PageLoaderURL from "../img/loader.gif";
import "swiper/swiper.css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { PageLoading } from "../packages";
import KKonnect_White from "../img/Kkonnect_White.webp";
import GridIcon from "../img/Grid.webp";
import checkVerified from "../img/check-verified.webp";
import ShieldZap from "../img/shield-zap.webp";
import { FaRegCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export const PublicLayout: React.FC<any> = ({ Component, ...rest }) => {
	const [pageLoadingData] = useSelector((state: any) => [state.pageLoading]);
	const location = useLocation();
	const page = location.pathname.split("/")[1];
	const adContent = [
		{
			icon: ShieldZap,
			text: "Instant Cost Estimation",
		},
		{
			icon: checkVerified,
			text: "Get Manufacturing Recommendations",
		},
		{
			icon: GridIcon,
			text: "Quick DFM Analysis",
		},
	];

	const Pentagon = () => (
		<svg width="100%" height="100%" viewBox="0 0 100 100">
			<path
				d="M50 5
           L95 38
           A5 5 0 0 1 97 42
           L82 95
           A5 5 0 0 1 77 98
           H23
           A5 5 0 0 1 18 95
           L3 42
           A5 5 0 0 1 5 38
           Z"
				fill="#DCF0FF"
			/>
		</svg>
	);
	return (
		<React.Fragment>
			<Flex
				justifyContent={
					["register", "verifyEmail"].includes(page)
						? "space-between"
						: "center"
				}
				bg={
					"radial-gradient(#4E88CF .6px, transparent 1.2px), linear-gradient(to right, #0F56AA, #1462BF, #1A71D9)"
				}
				backgroundSize="12px 12px, cover"
				minH="100vh"
				zIndex={10}
				alignItems="center"
				position="relative"
				overflow="hidden"
			>
				<Box
					position="absolute"
					width="27.5rem"
					height="27.5rem"
					top="-16rem"
					right="-5.5rem"
					opacity="10%"
					transform="rotate(110deg)"
				>
					<Pentagon />
				</Box>

				<Box
					position="absolute"
					width="27.5rem"
					height="27.5rem"
					transform="rotate(30deg)"
					bottom="3rem"
					right="-15rem"
					opacity="10%"
				>
					<Pentagon />
				</Box>
				{["register", "verifyEmail"].includes(page) && (
					<Box pl="10">
						<Image src={KKonnect_White} width="29%" />
						<Box>
							<Text
								color="#01FFAA"
								fontWeight={600}
								fontSize="2.375rem"
								lineHeight="2.8125rem"
								mt="1rem"
							>
								14 day free trial of our ACE <br /> Pro plan
							</Text>
							<Text
								color="#fff"
								mb={4}
								fontSize=".875rem"
							>{`(300 file uploads for 14 days)`}</Text>
							<Flex alignItems="center" color="#fff" gap=".5rem">
								<FaRegCheckCircle size="1.25rem" />
								<Text lineHeight="1.25rem">No credit card required</Text>
							</Flex>
						</Box>
						<Stack spacing="2rem" mt="4rem">
							{adContent?.map((item: any, index: number) => (
								<Flex alignItems="center" gap="1rem">
									<Image src={item.icon} width="2rem" alt="icon" />
									<Text color="#fff" lineHeight="1.5rem" fontWeight={600}>
										{item.text}
									</Text>
								</Flex>
							))}
						</Stack>
					</Box>
				)}
				<Flex
					flexDir="column"
					width={["register", "verifyEmail"].includes(page) ? "50%" : "40%"}
					m="2em"
					alignItems="center"
					justifyContent="center"
					gap="2rem"
				>
					{!["register", "verifyEmail"].includes(page) && (
						<Image src={KKonnect_White} width="27%" />
					)}
					<Box
						width="100%"
						h="inherit"
						backgroundColor="rgba(255, 255, 255, 0.15)"
						backdropFilter="blur(10px)"
						borderRadius="1rem"
						boxShadow={"lg"}
						border="1px solid #ffffff40"
					>
						<Component {...rest} />
					</Box>
				</Flex>
				{pageLoadingData?.loading && (
					<PageLoading
						pageLoading={pageLoadingData}
						pageLoaderURL={PageLoaderURL}
					/>
				)}
			</Flex>
		</React.Fragment>
	);
};
