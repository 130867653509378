import { phone } from "phone";
import * as Yup from "yup";

export const yupString = Yup.string().required("Required");

export const yupEmail = Yup.string()
  .email("Please enter valid email")
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Please enter valid email"
  )
  .required("Required");

export const yupContact = Yup.number()
  .required("Required")
  .typeError(`Please enter a valid phone number`)
  .integer(`Please enter a valid phone number without decimal values`)
  .positive(`Please enter a valid phone number`)
  .test(
    "onlyNumbers",
    `Please enter a valid phone number.`,
    (value: any) => phone(`+${value}`).isValid
  );

export const yupPassword = Yup.string()
  .required("Required")
  .min(8, "Password is too short - 8 chars minimum.");
// .matches(/(?=.*[0-9])/, "Password must contain a number.")

export const yupConfirmPassword = Yup.string()
  .oneOf([Yup.ref("password")], "Password must match")
  .required("Required");

export const yupBoolean = Yup.boolean();

export const yupNumber = Yup.number()
  .typeError(`Please enter a valid number`)
  .integer(`Please enter a valid number without decimal values`)
  .required("Required");
export const yupDecimal = Yup.number()
  .typeError(`Please enter a valid number`)
  .required("Required");
