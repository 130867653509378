import * as React from 'react';
import { Box, Flex , Image, Text } from '@chakra-ui/react';


export const PageLoading =  ({pageLoading, pageLoaderURL, fileUploadPageLoader} :any) => {
    return (
        pageLoading?.loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            position="fixed"
            bg="rgba(0,0,0,0.5)"
            inset={0}
            zIndex={9999}
          >
            {pageLoading.isFileUpload !== undefined && pageLoading.isFileUpload ? (
              <Flex flexDir={"column"} alignItems="center" minHeight="28rem" maxH={"35rem"} w="28rem" borderRadius="lg">
                <Flex minH="19rem" w="100%" bg="#0087ee" justifyContent="center" alignItems="center" p="1rem" borderTopRadius="lg">
                    <Image src={fileUploadPageLoader} w="9rem" h="9rem" />
                </Flex>
                <Box flexGrow={1} bg="#fff" p="1rem" w="100%" borderBottomRadius="lg" display="flex" alignItems="center" justifyContent={"center"}>
                    <Text fontSize="md" fontWeight="medium" textAlign="center">{pageLoading?.loadingText}</Text>
                </Box>
              </Flex>
            )
             : (
                <Flex alignItems="center" justifyContent="space-between" padding="1rem" gap="2rem" borderRadius="lg" bg="#fff">
                    <Image src={pageLoaderURL} w="3.5rem" h="3.5rem" />
                    <Text fontSize="md" fontWeight="medium" textAlign="center">{pageLoading?.loadingText}</Text>
                </Flex>
             )}
            
          </Flex>
        )
      );
}
