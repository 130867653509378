import React, { useRef } from "react";
import {
	Box,
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Heading,
	Img,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	motion,
	useAnimation,
	useMotionValue,
	useMotionValueEvent,
	useScroll,
	useTransform,
} from "framer-motion";
import { BsChevronDown } from "react-icons/bs";
import Marquee from "react-fast-marquee";
import STEPFileImg from "../../img/step-file.png";
import STPFileImg from "../../img/stp-file.png";
import Screenshots from "../../img/screengrabs-collage.png";
import ScrollableImageVideo from "./ScrollImageVideo";
import VideoImageFrame1 from "../../img/VideoImageFrame1.png";
import VideoImageFrame2 from "../../img/VideoImageFrame2.png";
import CostingAtYourDemand from "../../img/CostingAtYourDemand.png";
import KkonnectLogo from "../../img/Kkonnect-logo.svg";
import ProductVideo from "../../img/ProductVideo.gif";
import { Link } from "react-router-dom";
import ScrollDownIndicator from "./ScrollDownIndicator";
import { BiSolidRightArrowCircle } from "react-icons/bi";
import Lenis from "@studio-freight/lenis";
import { InteractiveBackground } from "./InteractiveBackground";
import Cookies from "js-cookie";
import { Mixpanel } from "../../Mixpanel.js";

const LandingPage = () => {
	const [mousePosition, setMousePosition] = React.useState({
		x: 0,
		y: 0,
	});
	const [cursorVariant, setCursorVariant] = React.useState("default");
	const [isNavbarHidden, setIsNavbarHidden] = React.useState(false);
	const [prevScrollPos, setPrevScrollPos] = React.useState(0);

	React.useEffect(() => {
		const mouseMove = (e: MouseEvent) => {
			setMousePosition({
				x: e.clientX,
				y: e.clientY,
			});
		};

		window.addEventListener("mousemove", mouseMove);

		return () => {
			window.removeEventListener("mousemove", mouseMove);
		};
	}, []);
	React.useEffect(() => {
		(window as any).dataLayer?.push({
			event: "ACE Page View",
			pageUrl: window.location.href,
			pageTitle: window.location.pathname,
			UserEmailId: Cookies.get("user_id") || "",
			userName: Cookies.get("full_name") || "",
		});

		Mixpanel.track("ACE Page View", {
			page_url: window.location.href,
			page_title: "ACE Landing Page",
			user_email: Cookies.get("user_id") || "",
			user_name: Cookies.get("full_name") || "",
		});
	}, []);

	const variants = {
		default: {
			x: mousePosition.x - 8,
			y: mousePosition.y - 8,
		},
	};

	const row1 = [
		"Visualize CAD designs instantly",
		"Material suitability breakdown",
		"Alternative materials to Al6061 suggested",
		"Discover cost-effective material options",
		"Unlock material possibilities for your CAD",
		"Optimal thin wall sizing for cost savings",
	];

	const row2 = [
		"Material cost analysis at a glance",
		"Thin wall dimensions evaluated",
		"Cost comparison: SS304 vs SS316",
		"Sample production cost insights",
		"Evaluating as-manufactured costs",
		"Analyze cost for production scale",
	];

	const row3 = [
		"Process recommendations for peak efficiency",
		"Detailed cost breakups, crystal clear",
		"DFM analysis for smarter manufacturing",
		"Costing for anodizing vs powder coating",
		"Exploded assembly views for better costing",
		"Surface finishing costs demystified",
	];

	const ref = useRef(null);
	const sectionRef = useRef(null);
	const controls = useAnimation();

	let { scrollYProgress } = useScroll({
		target: ref,
		offset: ["start start", "end start"],
	});

	// for hiding navbar when scrolled down
	// const handleScroll = () => {
	//   const currentScrollPos = window.scrollY;

	//   // Scroll down
	//   if (prevScrollPos < currentScrollPos && isNavbarHidden && currentScrollPos > 600) {
	//     setIsNavbarHidden(false);
	//     controls.start({ y: '-100%', transition: { duration: 0.3 } });
	//   }

	//   // Scroll up
	//   if (prevScrollPos > currentScrollPos && !isNavbarHidden) {
	//     setIsNavbarHidden(true);
	//     controls.start({ y: '0%', transition: { duration: 0.3 } });
	//   }

	//   setPrevScrollPos(currentScrollPos);
	// };

	// React.useEffect(() => {
	//   window.addEventListener('scroll', handleScroll);

	//   return () => {
	//     window.removeEventListener('scroll', handleScroll);
	//   };
	// }, [prevScrollPos]);

	// const navbarVariants = {
	//   visible: {opacity: 1, y: "0%"},
	//   hidden: {opacity: 0, y: "-100%"}
	// }

	let y = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
	let opacity = useTransform(scrollYProgress, [0, 0.8], [1, 0]);
	let scale = useTransform(scrollYProgress, [0, 1], ["100%", "80%"]);

	// for smooth scrolling
	React.useEffect(() => {
		const lenis = new Lenis();

		function raf(time: any) {
			lenis.raf(time);
			requestAnimationFrame(raf);
		}

		requestAnimationFrame(raf);
	}, []);

	const styles: any = {
		canvas: {
			height: "100vh!important",
			width: "80%!important",
			marginLeft: "auto!important",
			marginRight: "auto!important",
		},
	};

	return (
		<Box w="100%" position="relative">
			{/* ******* Navbar ************* */}
			<Flex
				as={motion.nav}
				// variants={navbarVariants}
				// animate={isNavbarHidden ? "hidden" : "visible"}
				p="1rem 2rem"
				w="100%"
				justifyContent="space-between"
				bg="linear-gradient(to right, #002F5F, #46A0FB99)"
				position="sticky"
				top="0"
				backdropFilter="blur(10px)"
				zIndex="999"
				alignItems="center"
			>
				<Text
					fontSize={{ sm: "20px", md: "22px", lg: "24px" }}
					fontWeight={600}
					color="#fff"
				>
					ACE
				</Text>
				<Button
					borderRadius="full"
					bg="#fff"
					color="#0087ee"
					_hover={{ bgColor: "#fff" }}
					as={Link}
					to={`/register`}
					target="_blank"
				>
					Try Ace
				</Button>
			</Flex>
			<Box position="absolute" top="0" left="0" w="100%" zIndex={1}>
				<InteractiveBackground />
			</Box>
			{/* ******* Hero *********** */}
			<Flex
				as={motion.div}
				h="100dvh"
				w="100%"
				alignItems="center"
				justifyContent="center"
				bg="linear-gradient(to right, #002F5F, #46A0FB)"
				ref={ref}
			>
				<Box>
					<Box
						minWidth="350px"
						display="flex"
						flexDir="column"
						alignItems="center"
						as={motion.div}
						style={{ opacity, scale, y }}
						position="relative"
						zIndex={3}
					>
						<Heading
							as="h1"
							fontSize={{ base: "3rem", sm: "3rem", md: "4rem", lg: "4.5rem" }}
							color="#fff"
							textAlign="center"
						>
							CNC Costing{" "}
							<Box as="span">
								<Heading
									as="h1"
									fontSize={{ sm: "3rem", md: "4rem", lg: "4.5rem" }}
									color="#8EFFE1"
								>
									Reimagined
								</Heading>
							</Box>
						</Heading>
						<Text
							color="#FFFFFF"
							mt="1.5rem"
							w={{ base: "80%", md: "70%", lg: "60%" }}
							textAlign="center"
							fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
						>
							Instant quotes, material insights, DFM analysis and process
							recommendations at your fingertips in seconds
						</Text>
						<Button
							bg="#fff"
							color="#317DCA"
							borderRadius="full"
							fontSize={{ sm: "16px", md: "18px", lg: "20px" }}
							lineHeight={{ sm: "17px", md: "20px", lg: "24px" }}
							mt="1rem"
							p={{ base: "1rem", md: "1rem 1.5rem", lg: "1.5rem 2rem" }}
							_hover={{
								border: "1px solid #fff",
								color: "#fff",
								bgColor: "#317DCA",
							}}
							transition="1s ease-in-out"
							as={Link}
							to={"/login"}
							target="_blank"
						>
							See it in action
						</Button>
						<Box mt="2rem">
							<ScrollDownIndicator targetSection={"#section2"} />
						</Box>
						<Box
							as={motion.div}
							variants={variants}
							animate={cursorVariant}
							className="cursor"
							bg="radial-gradient(circle, rgba(255,255,255,0.0) 0%,  rgba(255,255,255,0.0) 70%);"
							borderRadius="50%"
							position="fixed"
							top="0"
							left="0"
							boxShadow="0 0 10rem 5rem #ffffff90"
						></Box>
					</Box>
				</Box>
			</Flex>

			{/* ******* Section2 *********** */}
			<Flex
				w="100%"
				bg="#fff"
				p="3rem 2rem"
				flexDir="column"
				id="section2"
				ref={sectionRef}
			>
				<Box>
					<Text
						fontWeight={600}
						fontSize={{ base: "2.25rem", md: "2.75rem", lg: "3rem" }}
						whiteSpace="pre-line"
						textAlign="center"
					>
						{"Collaborate with AI in \n"}
						<Box
							as="span"
							bg="linear-gradient(to right, #002F5F, #46A0FB 100%)"
							backgroundClip="text"
						>
							Costing
						</Box>{" "}
						and{" "}
						<Box
							as="span"
							bg="linear-gradient(to right, #46A0FB, #002F5F 100%)"
							backgroundClip="text"
						>
							Manufacturing
						</Box>
					</Text>
					<Text
						w={{ base: "100%", md: "80%", lg: "70%" }}
						fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
						textAlign="center"
						mx="auto"
						my="1rem"
					>
						Upload your CAD files and let ACE be your expert guide in costing
						and manufacturability insights
					</Text>
				</Box>
				<Box
					w={{ base: "100%", md: "90%", lg: "80%" }}
					height={{ base: "auto", md: "70vh" }}
					borderRadius="2rem"
					border="1px solid #46A0FB"
					padding={{ base: "1rem", md: "0" }}
					mt="auto"
					mx="auto"
				>
					<Img
						src={ProductVideo}
						alt="product-video"
						objectFit="contain"
						h="100%"
						mx="auto"
					/>
				</Box>
			</Flex>

			{/* ******* Marquee Components ********** */}
			<Box
				p="3rem 0rem"
				w="100%"
				bg="#fff"
				position="relative"
				width="100vw"
				maxWidth="100%"
				overflowX="hidden"
			>
				<Box my="2rem">
					<Marquee pauseOnHover={true}>
						{row1.map((item, index) => (
							<Box
								borderRadius="full"
								p="1rem 2rem"
								color="#fff"
								bg="linear-gradient(to right, #002F5F, #46A0FB)"
								minW="200px"
								fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
								mr="1.5rem"
								key={index}
							>
								{item}
							</Box>
						))}
					</Marquee>
				</Box>

				<Box my="2rem">
					<Marquee direction="right" pauseOnHover={true}>
						{row2.map((item, index) => (
							<Box
								borderRadius="full"
								p="1rem 2rem"
								color="#fff"
								bg="linear-gradient(to right, #002F5F, #46A0FB)"
								minW="200px"
								fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
								mr="1.5rem"
								key={index}
							>
								{item}
							</Box>
						))}
					</Marquee>
				</Box>

				<Box my="2rem">
					<Marquee pauseOnHover={true}>
						{row3.map((item, index) => (
							<Box
								borderRadius="full"
								p="1rem 2rem"
								color="#fff"
								bg="linear-gradient(to right, #002F5F, #46A0FB)"
								minW="200px"
								fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
								mr="1.5rem"
								key={index}
							>
								{item}
							</Box>
						))}
					</Marquee>
				</Box>
			</Box>

			{/* ******* Ace Precision with Scrollable Video Image component ********* */}
			<Box
				w="100%"
				padding="3rem 1.5rem"
				bg="linear-gradient(to right, #002F5F, #46A0FB)"
				display="flex"
				flexDir="column"
				alignItems="center"
			>
				<Heading
					fontSize={{ base: "2.25rem", md: "2.75rem", lg: "3.25rem" }}
					color="#8EFFE1"
					textAlign="center"
				>
					ACE.
				</Heading>
				<Heading
					fontSize={{ base: "2.25rem", md: "2.75rem", lg: "3.25rem" }}
					color="#fff"
					textAlign="center"
				>
					Precision in Every Quote.
				</Heading>
				<Text
					fontSize={{ sm: "17px", md: "20px", lg: "24px" }}
					color="#fff"
					textAlign="center"
				>
					Our algorithms analyze just like your best costing engineer.
				</Text>
				<Box my="2rem" w="100%" mx="auto">
					{/* <Img  src={VideoImageFrame1} alt="video-frame-image"/> */}
					<ScrollableImageVideo
						imagePaths={[VideoImageFrame1, VideoImageFrame2]}
					/>
				</Box>
				<Divider my="2rem" bg="#fff" w="80%" />
				<Box display="flex" flexDir="column" alignItems="center">
					<Text
						textAlign="center"
						color="#fff"
						fontSize={{ sm: "15px", md: "18px", lg: "20px" }}
					>
						Works with your CAD files
					</Text>
					<Flex
						alignItems="center"
						justifyContent="center"
						mt="2rem"
						gap="2rem"
					>
						<Img src={STEPFileImg} alt="step-file-icon" />
						<Img src={STPFileImg} alt="stp-file-icon" />
					</Flex>
				</Box>
			</Box>

			{/* ******* Key Features Section *************  */}
			<Flex
				alignItems="center"
				justifyContent="center"
				gap="2rem"
				bg="#DCF0FF"
				p="3rem 2rem"
				flexDir={{ base: "column", md: "row" }}
			>
				<Img
					src={Screenshots}
					alt="screenshots-img"
					w={{ base: "100%", md: "50%" }}
				/>
				<Stack w={{ base: "100%", md: "50%" }}>
					<Stack>
						<Text fontWeight="600" fontSize="24px">
							Accurate
						</Text>
						<Text color="#6B7280" fontSize="20px">
							With our advanced algorithms and extensive database, ACE delivers
							unparalleled precision in manufacturing cost estimation.
						</Text>
					</Stack>

					<Stack>
						<Text fontWeight="600" fontSize="24px">
							Secure
						</Text>
						<Text color="#6B7280" fontSize="20px">
							Rest easy knowing your files are stored securely, maintained with
							the highest standards of data protection.
						</Text>
					</Stack>
				</Stack>
			</Flex>

			{/* ******* Costing at your command Section *********** */}
			<Box
				w={{ base: "100%", md: "70%" }}
				h="auto"
				bg="linear-gradient(to right, #002F5F, #46A0FB)"
				position="relative"
				zIndex={1}
				mx="auto"
				bottom={{ base: "0", md: "-8rem" }}
				borderRadius={{ base: "none", md: "1rem" }}
				display="flex"
				alignItems="center"
				flexDir={{ base: "column", md: "row" }}
				justifyContent="space-between"
				px={{ base: "1.5rem", md: "2rem 0" }}
				py="2rem"
				gap={{ base: "1.5rem", md: "none" }}
			>
				<Stack
					alignItems="left"
					w={{ base: "100%", md: "50%" }}
					textAlign={{ base: "center", md: "start" }}
				>
					<Text color="#8EFFE1" fontSize="30" fontWeight={600}>
						Costing at Your Command
					</Text>
					<Text color="#D5EAFF">
						Your designs, our AI-driven costing - accurate, instant, anytime
					</Text>
					<Button
						variant="link"
						color="#fff"
						ml={{ base: "auto", md: "0" }}
						mr={{ base: "auto", md: "auto" }}
						w="fit-content"
						as={Link}
						to={"/login"}
						fontSize="20px"
						target="_blank"
					>
						Start Costing Now&nbsp;
						<BiSolidRightArrowCircle />
					</Button>
				</Stack>
				<Img
					w={{ base: "100%", md: "50%" }}
					src={CostingAtYourDemand}
					alt="costing-at-your-demand-img"
				/>
			</Box>

			{/* *********** Footer ************* */}
			<Box
				bg="#1C1C1C"
				position="relative"
				padding={{ base: "2rem", md: "12rem 3rem 2rem" }}
				minH="70vh"
				maxH="auto"
			>
				<Grid
					templateColumns={{ base: "1fr", md: "1.5fr 1fr 1fr 1fr" }}
					templateRows={{ md: "1fr", base: "auto auto auto auto" }}
					columnGap={{ base: "0", md: "1.5rem" }}
					rowGap={{ base: "1.5rem", md: "0rem" }}
				>
					<GridItem color="#fff">
						<Stack
							alignItems={{ base: "center", md: "start" }}
							padding="1rem 2rem"
							border="1px solid #AEAEAE"
							borderRadius="lg"
							w="fit-content"
						>
							<Img src={KkonnectLogo} maxW="250px" alt="logo" />
							<Text fontWeight="semi-bold" color="#fff">
								Contact
							</Text>
							<Link to={`mailto:${"ace@kkonnect.io"}`} color="#AEAEAE">
								ace@kkonnect.io
							</Link>
							<Link to="tel:+919987858416">+919987858416</Link>
						</Stack>
					</GridItem>
					<GridItem color="#fff">
						<Grid
							templateRows="1fr 1fr"
							templateColumns={"1fr"}
							rowGap="1rem"
							textAlign={{ base: "center", md: "left" }}
						>
							<GridItem color="#AEAEAE" fontWeight="600">
								Company
							</GridItem>
							<GridItem>
								<Link target="_blank" to="https://www.kkonnect.io/about-us">
									About
								</Link>
							</GridItem>
						</Grid>
					</GridItem>
					<GridItem color="#fff" textAlign={{ base: "center", md: "left" }}>
						<Grid
							templateRows="1fr 1fr 1fr 1fr"
							templateColumns={"1fr"}
							rowGap="1rem"
						>
							<GridItem color="#AEAEAE" fontWeight="600">
								Product
							</GridItem>
							{[
								{
									name: "Buyer Portal",
									link: "https://buyer.kkonnect.io/login",
								},
								{
									name: "Supplier Portal",
									link: "https://supplier.kkonnect.io/",
								},
								{
									name: "Supplier Discovery",
									link: "https://dir.kkonnect.io/home",
								},
							].map((item, index) => (
								<GridItem color="#fff" key={index}>
									<Link target="_blank" to={item.link}>
										{item.name}
									</Link>
								</GridItem>
							))}
						</Grid>
					</GridItem>
					<GridItem color="white" textAlign={{ base: "center", md: "left" }}>
						<Grid
							templateRows="1fr 1fr 1fr"
							templateColumns={"1fr"}
							rowGap="1rem"
						>
							<GridItem color="#AEAEAE" fontWeight="600">
								Terms
							</GridItem>
							<GridItem color="#fff">
								<Link
									target="_blank"
									to="https://www.kkonnect.io/privacy-policy"
								>
									Privacy Policy
								</Link>
							</GridItem>
							<GridItem color="#fff">
								<Link
									target="_blank"
									to="https://www.kkonnect.io/terms-conditions"
								>
									Terms & Conditions
								</Link>
							</GridItem>
						</Grid>
					</GridItem>
				</Grid>
			</Box>
		</Box>
	);
};

export default LandingPage;
