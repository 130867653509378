import axios from "axios";

export const calculateCost = (data: any) => {
  return axios.post(
    `/api/method/extractor_costing_frappe_app.api.costing.calculator`,
    data
  );
};

export const addFeedback = (feedback : any) => {
	return axios.post(
		`/api/method/extractor_costing_frappe_app.api.feedback.post_feedback`,
		feedback
	);
};
