import {
	REGISTER_FAIL,
	SET_MESSAGE,
	LOGOUT,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	authRegisterService,
	formatErrorMessage,
	authLoginService,
	authLogoutService,
	history,
	ERROR_MSG,
	SUCCESS_MSG,
} from "../../../../packages";

import { setMessage, setPageLoading } from "./message";
import { clearSubscriptionPlan } from "./subscription";

export const registerAce = (data: any) => (dispatch: any) => {
	return authRegisterService(data).then(
		(response: any) => {
			return Promise.resolve(response);
		},
		(error) => {
			const message = formatErrorMessage(error);

			dispatch({
				type: REGISTER_FAIL,
			});

			dispatch(setPageLoading({ loading: false, loadingText: "" }));

			dispatch({
				type: SET_MESSAGE,
				payload: { case: ERROR_MSG, data: message },
			});

			return Promise.reject(error);
		}
	);
};

export const loginAce =
	(username: string, password: string) => (dispatch: any) => {
		return authLoginService(username, password).then(
			(data: any) => {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: { user: data.data },
				});
				localStorage.setItem("ace", username);
				if (data.data.message == "Logged In")
					dispatch({
						type: SET_MESSAGE,
						payload: { case: LOGIN_SUCCESS, data: "Logged In !" },
					});
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: SUCCESS_MSG,
						data: "Logged In !",
					},
				});
				dispatch(setMessage(""));
				history.push("/fileupload");
				return Promise.resolve(data);
			},

			// (error: any) => {
			//   const message = formatErrorMessage(error);

			//   dispatch({
			//     type: LOGIN_FAIL,
			//   });

			//   dispatch({
			//     type: SET_MESSAGE,
			//     payload: { case: LOGIN_FAIL, data: message },
			//   });
			//   dispatch(setMessage(""));

			//   return Promise.reject();
			// }
			(error: any) => {
				let message = "";
				const status = error?.response?.status;
				if (status < 500 && status >= 400) {
					message = "Wrong email or password";
				} else if (status >= 500) {
					message = "Permission deined.";
				} else {
					message = formatErrorMessage(error);
				}

				dispatch({
					type: LOGIN_FAIL,
				});
				dispatch(setPageLoading({ loading: false, loadingText: "" }));
				dispatch({
					type: SET_MESSAGE,
					payload: { case: ERROR_MSG, data: message },
				});
			}
		);
	};

export const logoutAce = () => (dispatch: any) => {
	return authLogoutService()
		.then((data: any) => {
			dispatch(clearSubscriptionPlan());
			dispatch({
				type: LOGOUT,
			});
			localStorage.clear();
			sessionStorage.clear();

			window.location.reload();
			history.push("/login");
		})
		.catch((error) => {
			dispatch({
				type: SET_MESSAGE,
				payload: {
					case: ERROR_MSG,
					data: "Failed to log out " + formatErrorMessage(error),
				},
			});
		});
};
