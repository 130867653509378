var objToday = new Date();
const months = new Array(
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
);
const weekday = new Array(
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday"
);

export const dateMonthDay =
	objToday.getDate() +
	" " +
	months[objToday.getMonth()] +
	", " +
	weekday[objToday.getDay()];

export const normalDate = (date: Date) => {
	const objDate = new Date(date);

	const dt =
		objDate.getDate() < 10 ? `0${objDate.getDate()}` : objDate.getDate();
	const mon =
		objDate.getMonth() + 1 < 10
			? `0${objDate.getMonth() + 1}`
			: objDate.getMonth() + 1;
	return dt + "/" + mon + "/" + objDate.getFullYear();
};

export const YYYY_MM_DD = (date: Date) => {
	const objDate = new Date(date);

	const dt =
		objDate.getDate() < 10 ? `0${objDate.getDate()}` : objDate.getDate();
	const mon =
		objDate.getMonth() + 1 < 10
			? `0${objDate.getMonth() + 1}`
			: objDate.getMonth() + 1;
	return objDate.getFullYear() + "/" + mon + "/" + dt;
};

export const convertToHoursAndMinutes = (unit: string, value: number) => {
	let hours, minutes, seconds;
	let time = "";
	switch (unit) {
		case "hrs":
			hours = Math.floor(value);
			minutes = Math.floor((value - hours) * 60);
			seconds = Math.round(((value - hours) * 3600) % 60);
			break;
		case "mins":
			hours = Math.floor(value / 60);
			minutes = Math.floor(value % 60);
			seconds = Math.round((value % 1) * 60);
			break;
		case "seconds":
			hours = Math.floor(value / 3600);
			minutes = Math.floor((value % 3600) / 60);
			seconds = Math.round(value % 60);
			break;
		default:
			return "Invalid unit";
	}

	// Handle rounding of seconds correctly
	if (seconds === 60) {
		seconds = 0;
		minutes += 1;
	}

	if (minutes === 60) {
		minutes = 0;
		hours += 1;
	}

	if (hours > 0) {
		time += `${hours} ${hours === 1 ? "hr" : "hrs"} `;
	}
	if (minutes > 0) {
		time += `${minutes} ${minutes === 1 ? "min" : "mins"} `;
	}
	if (seconds > 0 || (hours === 0 && minutes === 0)) {
		time += `${seconds} ${seconds === 1 ? "sec" : "secs"}`;
	}

	return time.trim();
};
