import {
	SET_SUBSCRIPTION_PLAN,
	CLEAR_SUBSCRIPTION_PLAN,
} from "../actions/types";

const initialState = {
	plan: "Free Plan",
	used_file_uploads: 0,
	allocated_file_uploads: 0,
	plan_status: "Expired",
	plan_expiry_date: "",
};

export const subscriptionPlan = function (state = initialState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case SET_SUBSCRIPTION_PLAN:
			return payload;
		case CLEAR_SUBSCRIPTION_PLAN:
			return state;

		default:
			return state;
	}
};
