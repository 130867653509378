import axios from "axios";

const config = {
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

export const authRegisterService = (data: any) => {
	return axios.post("/api/method/ace_be_app.api.auth.signup", data);
};

export const authLoginService = (username: string, password: string) => {
	return axios.post(
		"/api/method/login",
		JSON.stringify({ usr: username, pwd: password }),
		config
	);
};

export const authLogoutService = () => {
	return axios.post("/api/method/logout").then((response) => {
		return response.data;
	});
};

export const resetPasswordRequestService = (payload: { email: string }) => {
	return axios.post("/api/method/ace_be_app.api.auth.reset_password", payload);
};
