import axios from "axios";

interface GetDoc {
  doc: string;
  filters?: string[][];
  limit_start?: number;
  fields?: string[];
  order_by?: string;
  limit?: number;
}


export const get_doc = async ({
  doc,
  filters,
  limit_start,
  fields,
  order_by,
  limit
}: GetDoc) => {
  return (
    await axios.get(
      `/api/resource/${doc}`,
      {
        params: {
          ...(filters && { filters: JSON.stringify(filters) }),
          ...(limit_start && { limit_start: JSON.stringify(limit_start) }),
          ...(fields && { fields: JSON.stringify(fields) }),
          ...(order_by && { order_by: order_by }),
          ...((typeof(limit) === 'number') && { limit: JSON.stringify(limit) }),
        },
      }
    )
  );
};
