import React from "react";
import { Box, Input } from "@chakra-ui/react";
export const GlobalFilter = ({ filter, setFilter, placeholderText }: any) => {
  return (
    <Box mb="1em">
      Search : {""}
      <Input
        value={filter || ""}
        width="25em"
        onChange={(e) => setFilter(e.target.value)}
        placeholder={placeholderText !== undefined ? placeholderText : null }
      />
    </Box>
  );
};