import { SET_SUBSCRIPTION_PLAN, CLEAR_SUBSCRIPTION_PLAN } from "./types";

export const setSubscriptionPlan = (plan: any) => ({
	type: SET_SUBSCRIPTION_PLAN,
	payload: plan,
});

export const clearSubscriptionPlan = () => ({
	type: CLEAR_SUBSCRIPTION_PLAN,
});
