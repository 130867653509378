import React from "react";
import PageLoaderURL from "../img/page-loader.gif";
import karkhanaLogo from "../img/Kkonnect 1.svg";
import { AppShell } from "../AppShell";

const PrivateLayout = ({
	Component,
}: {
	Component: React.LazyExoticComponent<React.FC<any>>;
}) => {
	const [notification, setNotification] = React.useState([]);

	return (
		<AppShell
			children={Component}
			imageURL={karkhanaLogo}
			pageLoaderURL={PageLoaderURL}
			notifications={notification}
			navigateToDoc={() => {}}
		/>
	);
};

export default PrivateLayout;
