import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";

export const store = configureStore({
	reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export default store;

export {
	setMessage,
	clearMessage,
	setLoading,
	setPageLoading,
	updateOnboardStatus,
} from "./actions/message";
export {
	setSubscriptionPlan,
	clearSubscriptionPlan,
} from "./actions/subscription";
export {
	LOGIN_SUCCESS,
	USERID_FETCH_SUCCESS,
	UPDATE_SIGNUP_STATE,
	LOGIN_FAIL,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGOUT,
	API_LOADING,
	SET_MESSAGE,
	CLEAR_MESSAGE,
	SUCCESS_MSG,
	ERROR_MSG,
	INFO_MSG,
	PAGE_LOADING,
	UPDATE_ONBOARD_STATUS,
	SET_SUBSCRIPTION_PLAN,
	CLEAR_SUBSCRIPTION_PLAN,
} from "./actions/types";
