import { combineReducers } from "redux";
import { AceAuth } from "./AceAuth";
import { message, apiLoading, pageLoading } from "./message";
import { subscriptionPlan } from "./Subsription";

export default combineReducers({
	AceAuth,
	message,
	apiLoading,
	pageLoading,
	subscriptionPlan,
});
